@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchandise-tag {
  margin: 6px 12px 6px 0;
  display: grid;
  grid-template-columns: 22px 1fr;
  column-gap: 4px;
  align-items: center;
  font-size: rem(12);
  line-height: rem(14);
  color: $text-color;

  &--frosty {
    color: #33b5e5;
  }

  &__icon {
    background-color: $light-grey-color-200;
    border-radius: 50%;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--frosty {
      background-color: rgb(51 181 229 / 10%);
    }
  }
}
