@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-icon {
  justify-content: center;
  width: 100%;
  display: none;

  &--mobile {
    @include mq('tablet', max) {
      display: flex;
    }
  }

  &--desktop {
    @include mq('tablet') {
      display: flex;
    }
  }

  @keyframes ballon-1-animation {
    0% {
      transform: translate(0, 6px);
    }

    50% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(0, 6px);
    }
  }

  @keyframes ballon-2-animation {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 5px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes plant-animation {
    0% {
      transform: rotateZ(0);
    }

    50% {
      transform: rotateZ(5deg);
    }

    100% {
      transform: rotateZ(0);
    }
  }

  #ballon-1 {
    transform-origin: center;
    animation: ballon-1-animation 6s infinite;
    animation-timing-function: ease-in-out;
  }

  #ballon-2 {
    transform-origin: center;
    animation: ballon-2-animation 6s infinite;
    animation-timing-function: ease-in-out;
  }

  #plant-1 {
    transform-origin: 150px 90px;
    animation: plant-animation 5s infinite;
    animation-timing-function: ease-in-out;
  }

  #plant-2 {
    transform-origin: 76px 89px;
    animation: plant-animation 4s infinite;
    animation-timing-function: ease-in-out;
  }
}
