@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-card-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: flex-start;
  gap: 6px;

  .product-card-image__container {
    order: 1;
  }

  .product-card__price {
    order: 2;
  }

  .dish-card__tag {
    order: 3;
  }

  .take-X-pay-Y-tag {
    margin-top: 0;
    order: 3;
  }

  .product-card__description {
    order: 4;
  }

  .product-card__details {
    order: 5;
  }

  @include mq('tablet') {
    width: 122px;
  }

  text-decoration: none;
}
