@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-restaurant {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(63 62 62 / 10%);
  border-radius: $border-radius-default;
  width: 100%;
  margin: 0;
  padding: 10px;
  overflow: visible;
  background: transparent;
  height: auto;
  text-decoration: none;

  @include mq('desktop-s') {
    padding: 11px 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__evaluation {
    display: flex;
    font-size: rem(12);
    font-weight: 500;
    color: $mostard-color;

    @include colorize-icon($mostard-color);

    svg {
      width: 10px;
      height: 10px;
    }

    &-value {
      margin-left: 4px;

      &--new {
        font-size: rem(12);
        color: $mostard-color;
      }
    }
  }

  &__delivery-time,
  &__description {
    font-size: rem(12);
    font-weight: 400;
    text-decoration: none;
    line-height: 1;
  }

  &__description {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__title {
    color: $black-color;
    margin: 0 8px 0 4px;
  }

  &__divisor {
    border-top: 2px solid $light-grey-color-100;
    border-top-style: dashed;
    margin: 8px 0;
  }

  &__footer {
    display: flex;
    align-items: baseline;
  }

  &__dot-divisor {
    color: $text-color;
    margin: 0 4px;
  }

  &__delivery-time {
    color: $text-color;

    .icon-marmita--scheduling {
      top: 1px;
      margin-right: 4px;
    }
  }

  &__delivery-price {
    color: $text-color;
    font-size: rem(12);

    &--free {
      color: $green-color;
      font-weight: 300;
    }
  }
}
