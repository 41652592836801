@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-maps-toast {
  opacity: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 16px;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 480px;
  height: auto;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #ef5753;
  border-radius: $border-radius-default;
  padding: 12px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  color: white;
  font-size: rem(14);
  line-height: rem(18);
  transition: all ease-in 150ms;

  .icon-marmita {
    height: 14px;
    width: 14px;
    background-color: white;
    border-radius: 100%;

    @include colorize-icon(#ff7383);

    svg {
      height: 18px;
      width: 18px;
      transform: translate(-2px, -2px);
    }
  }

  &--active {
    opacity: 1;
  }
}
