@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-card-scale-price-tag {
  background-color: $green-color;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: rem(10);
  line-height: rem(12);
  font-weight: 500;
  color: $white-color;
  display: inline-flex;
  margin-top: 2px;
}
