@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchandise-tags-info-tooltip {
  position: absolute;
  top: 50px;
  right: -16px;
  z-index: 21;
  width: 390px;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 2px 43px rgb(0 0 0 / 24%);

  &__icon {
    position: absolute;
    top: -12px;
    right: 14px;
  }
}
