@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-modal {
  &__spinner {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: calc(100% - 38px);
  }

  &--loading {
    .marmita-modal__inner-content-scroll {
      height: 100%;
    }
  }

  @include mq('desktop', 'max') {
    .marmita-modal__inner-content {
      max-width: 960px;
    }
  }

  @include mq('desktop-s', 'max') {
    // removing custom scrollbar from .marmita-modal
    .marmita-modal__inner-content {
      &::-webkit-scrollbar-track {
        border-radius: inherit;
        background-color: inherit;
      }

      &::-webkit-scrollbar {
        width: inherit;
        background-color: inherit;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: inherit;
        background-color: inherit;
      }
    }
  }

  @include mq('desktop-s') {
    width: fit-content;
    min-width: 400px;
    min-height: 400px;
    max-width: none;
    height: fit-content;
    border-radius: $border-radius-default;

    .marmita-modal__inner-content {
      height: fit-content;
    }

    &__overlay {
      background-color: color.adjust($text-color, $alpha: -0.1);
      z-index: $restaurant-dish-overlay;
    }

    &.marmita-modal__content {
      & &__overlay {
        transform: none;
        background-color: color.adjust($text-color, $alpha: 1);
      }

      &--after-open {
        & &__overlay {
          background-color: color.adjust($text-color, $alpha: 1);
        }
      }

      .marmita-modal__inner-content-scroll {
        height: 100%;
      }
    }
  }
}
