@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-catalog-aisles {
  .menu-group-list {
    max-width: 100%;
    margin: auto;
    animation: translate-fade-in 300ms ease-in-out forwards;
    padding: 0;

    @include mq('tablet') {
      padding: 0 44px;
    }

    @include mq('desktop-s') {
      padding: 0 8px 100px;
    }

    &__title {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(20);
      padding: 40px 16px 20px;
      margin: 0;
      color: $black-color;

      @include mq('tablet') {
        padding: 40px 0 20px;
      }

      @include mq('desktop-s') {
        font-weight: 400;
        font-size: rem(22);
        line-height: rem(22);
        padding: 50px 0 20px;
      }

      @include mq('desktop-wide') {
        padding-top: 60px;
      }
    }

    .carousel__controller--right {
      right: -50px;

      @include mq('desktop-wide') {
        right: -68px;
      }
    }

    .carousel__controller--left {
      left: -50px;

      @include mq('desktop-wide') {
        left: -68px;
      }
    }
  }
}

@keyframes translate-fade-in {
  from {
    transform: translateY(-30px);
  }

  to {
    transform: translateY(0);
  }
}
