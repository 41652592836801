@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.menu-group-list-carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    margin-right: 12px;
  }

  &:last-child {
    margin-right: 0;
  }

  .group-card__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
}

.menu-group-list-scrollbox {
  height: auto;

  .marmita-scrollbox__wrapper {
    padding: 0;
  }
}
