@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-card {
  &__description {
    display: flex;
    color: $text-color-super-darken;
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(20);
    max-height: calc(2 * rem(20));
  }

  &__details {
    font-size: rem(12);
    line-height: rem(16);
    max-height: calc(2 * rem(32));
    font-weight: lighter;
    color: $text-color;

    @include mq('tablet') {
      font-size: rem(14);
      line-height: rem(18);
      max-height: calc(2 * rem(36));
    }
  }

  &__description,
  &__details {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
  }
}
