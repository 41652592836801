@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-carousel {
  margin: auto;
  animation: translate-fade-in 300ms ease-in-out forwards;

  @include mq('tablet') {
    padding: 0 44px;
  }

  @include mq('desktop-s') {
    padding: 0 64px;
  }

  &--full-page {
    padding: 0 8px;
  }

  .marmita-scrollbox {
    height: 260px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 20px;

    @include mq('tablet') {
      padding-left: 0;
      padding-right: 0;
    }

    @include mq('desktop-s') {
      padding: 20px 0;
    }

    @include mq('desktop-ultrawide') {
      padding: 60px 0 20px;
    }

    .btn {
      height: unset;
      padding: 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(16);
    line-height: rem(20);
    margin: 0;
    color: $black-color;

    @include mq('desktop-s') {
      font-weight: 400;
      font-size: rem(24);
      line-height: rem(28);
    }
  }

  &__description {
    font-size: rem(14);
    line-height: rem(18);
    margin: 2px 0 0;
    color: $text-color;

    @include mq('desktop-s') {
      font-size: rem(22);
      line-height: rem(22);
      margin-top: 6px;
      font-weight: 100;
    }
  }

  &__container {
    width: 100%;
    display: grid;
    grid-gap: 0;
    margin: 0;
    grid-template-columns: repeat(calc(var(--itemsPerCarouselSection) - 1), minmax(139px, 1fr)) 122px;
  }

  &__wrapper {
    .carousel__controller--right {
      right: -50px;
      top: 42px;

      @include mq('desktop-wide') {
        right: -68px;
      }
    }

    .carousel__controller--left {
      left: -50px;
      top: 42px;

      @include mq('desktop-wide') {
        left: -68px;
      }
    }
  }
}

@keyframes translate-fade-in {
  from {
    transform: translateY(-30px);
  }

  to {
    transform: translateY(0);
  }
}
