@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$product-card-image-size-mobile: 100px;
$product-card-image-size-tablet: 122px;

.product-card-image {
  &__container {
    display: flex;
    overflow: hidden;
    height: $product-card-image-size-mobile;
    width: $product-card-image-size-mobile;
    padding: $space-yellow;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-thin-pink;
    transition: filter 0.5s;
    position: relative;
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;

    &--black-and-white {
      filter: grayscale(1);
    }

    @include mq('tablet') {
      width: $product-card-image-size-tablet;
      height: $product-card-image-size-tablet;
    }
  }

  &__content {
    object-fit: contain;
    width: 100%;
  }

  &__overlay {
    position: absolute;
    width: $product-card-image-size-mobile;
    height: $product-card-image-size-mobile;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 5%);

    @include mq('tablet') {
      width: $product-card-image-size-tablet;
      height: $product-card-image-size-tablet;
    }
  }

  &__sold-out {
    color: #fff;
    position: absolute;
    width: $product-card-image-size-mobile;
    height: $product-card-image-size-mobile;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 39%);
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq('tablet') {
      width: $product-card-image-size-tablet;
      height: $product-card-image-size-tablet;
    }
  }
}
