@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-scale-price-info {
  display: grid;
  grid-template-columns: 26px 1fr;
  column-gap: 8px;
  align-items: center;
  margin-top: 10px;

  &__text {
    font-weight: 300;
    font-size: rem(12);
    line-height: rem(16);
    margin: 0;
    color: $text-color;
  }
}
