@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.menu-group-list {
  position: relative;
  padding: 33px 0 0;

  @include mq('tablet') {
    padding: 40px 0 0;
  }

  @include mq('desktop-s') {
    padding: 60px $padding-default-desktop 0;
  }

  @include mq('desktop') {
    max-width: 1366px;
    margin: auto;
  }

  @include mq('desktop-wide') {
    padding-right: 30px;
  }

  &__title {
    position: relative;
    font-weight: 500;
    font-size: rem(18);
    padding: 0 $padding-default 20px $padding-default;
    margin: 0;
    color: $black-color;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq('desktop-s') {
      font-size: rem(24);
      letter-spacing: -1px;
      padding: 0 0 30px;
    }
  }

  .carousel__controller--right {
    right: -25px;
    top: 19%;
  }

  .carousel__controller--left {
    left: -25px;
    top: 19%;
  }

  .marmita-scrollbox {
    &__container {
      & > *:first-child {
        margin-left: 20px;
      }
    }

    &__item {
      margin-right: 12px;
    }
  }
}
