@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-options {
  &__container {
    align-self: flex-start;
    background: $primary-color;
    display: flex;
    opacity: 0;
    padding-left: $padding-default;
    padding-right: 10px;
    padding-top: 10px;
    z-index: 2;

    @include mq('desktop-s') {
      align-self: center;
      background: transparent;

      .btn-icon {
        @include colorize-icon($primary-color);
      }
    }

    .btn-icon {
      animation: fade-in $animation-duration ease 1 forwards;
      cursor: pointer;
      margin: 0 18px 0 0;
      width: 25px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
