@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish {
  overflow-y: auto;
  height: calc(100% - 100px);
  position: relative;
  z-index: 0;
  width: 100%;

  @include mq('tablet') {
    position: absolute;
  }

  @include mq('desktop-s') {
    height: calc(100% - 138px);
  }

  &__error-message {
    // close button spacing
    margin-top: 60px;

    @include mq('desktop-s') {
      margin-top: 20px;
    }
  }

  &__container {
    @include mq('desktop-s') {
      height: 100%;
    }
  }

  &__heading-wrapper {
    overflow-y: auto;
    height: calc(100% - 100px);
    position: relative;
  }

  &__loop-text {
    height: 64px;
    border-radius: $border-radius-default;
    color: $text-color-super-darken;
    bottom: 100px;
    right: 0;
    display: flex;
    font-size: rem(14);
    padding: 23px 14px;
    margin: 0 20px 15px;
    align-items: center;
    justify-content: center;
    background: $light-grey-color-200;
    box-shadow: 0 4px 16px rgb(0 0 0 / 12%);

    @include mq('tablet') {
      position: absolute;
    }

    @include mq('desktop-s') {
      width: 370px;
      margin: auto 20px 12px auto;
    }
  }
}
