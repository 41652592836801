@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.group-card {
  position: relative;
  display: grid;
  min-height: 147px;
  width: 100%;
  background: #fff;
  padding: 20px;
  text-decoration: none;
  transition: 200ms;
  overflow: hidden;

  @media only screen and (max-width: 742px) {
    position: relative;

    @include thin-border-after;
  }

  @media only screen and (min-width: 743px) {
    padding: 15px;
    height: 178px;
    min-width: 320px;
    border: 1px solid $light-grey-color-100;
    box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
    border-radius: $border-radius-default;

    &:hover {
      border: 1px solid $medium-grey-color-lighten;
    }
  }

  &__name-container {
    margin-top: 8px;
    text-align: center;

    @include mq('tablet') {
      margin-top: 12px;
    }
  }

  &__name {
    font-size: rem(12);
    line-height: rem(16);
    font-weight: lighter;
    color: $text-color;
    max-width: 140px;
    display: block;

    // break-word is deprecated, but it works
    // the intended way for every major browser
    // see: https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
    word-break: break-word;

    @include mq('tablet') {
      font-size: rem(14);
    }
  }

  &__container {
    flex: 0;
    min-width: 84px;
    text-align: center;

    @include mq('desktop-s') {
      min-width: 140px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__image {
    height: 84px;
    width: 84px;
    object-fit: contain;
    pointer-events: none;

    @include mq('desktop-s') {
      height: 100px;
      width: 100px;
    }
  }
}
