@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.ph-highlights-carousel-item {
  width: 100%;

  &__figure {
    width: 100%;
    height: 140px;
    margin: 0;
    border-radius: $border-radius-default;

    @include mq('tablet') {
      height: 200px;
    }
  }
}
