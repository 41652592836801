@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.garnish-choices {
  &__header {
    padding: 12px 20px 10px;
    display: block;
    background: $light-grey-color-100;
    z-index: $garnish-choice-sticky-z-index;
    position: sticky;
    top: 0;

    @include mq('desktop-s') {
      padding: 12px 40px 10px;
    }

    &::after {
      content: ' ';
      position: relative;
      display: block;
      width: 100%;
      left: 0;
      top: 9px;
      height: 1px;
      background-color: $light-grey-color;
    }
  }

  &__label {
    position: relative;
    width: 100%;
    display: block;

    &::after {
      content: ' ';
      position: relative;
      display: block;
      width: calc(100% - 40px);
      left: 20px;
      height: 1px;
      background-color: $light-grey-color-200;
    }

    &:last-child {
      &::after {
        display: none;

        @include mq('desktop-s') {
          display: block;
        }
      }
    }
  }

  &__choice {
    &--is-fulfilled {
      width: 16px;
      height: 11px;
      margin-right: 6px;

      @include colorize-icon($green-color);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px $padding-default;

    @include mq('desktop-s') {
      padding: 16px 40px;
    }

    .marmita-radio {
      margin-right: 2px;
    }
  }

  &__header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__option-desc {
    margin: 0;
    font-size: rem(16);
    line-height: 1.27;
    color: $black-color;
    width: 77%;
    font-weight: 100;
  }

  &__option-details {
    margin: 4px 0 0;
    font-size: rem(15);
    line-height: 1.2;
    font-weight: 300;
    color: #717171;
    display: block;
    padding: 0 20px 0 0;
    overflow-wrap: break-word;
  }

  &__option-img {
    border-radius: 2px;

    @include mq('mobile') {
      width: 60px;
      height: 45px;
    }

    @include mq('tablet') {
      width: 70px;
      height: 70px;
    }

    @include mq('desktop') {
      width: 85px;
      height: 85px;
    }
  }

  &__title {
    margin: 0;
    font-size: rem(16);
    line-height: em(20);
    font-weight: 500;
    color: $black-color;

    &-desc {
      font-weight: 100;
      font-size: rem(14);
      line-height: 17px;
      display: block;
      color: #717171;
    }
  }

  &__tags {
    display: inline-block;
  }
}
