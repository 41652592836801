@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-modal {
  &__header {
    &--transparent {
      visibility: hidden;
    }

    .nav-header {
      position: fixed;
      width: 100%;
      box-shadow: none;

      &__right-button {
        display: none;
      }

      @include mq('desktop-s') {
        position: static;
        width: auto;
        justify-content: flex-end;
        padding: 6px 6px 0;
        min-height: auto;
        height: auto;

        &__title {
          position: static;
        }

        &__left-slot {
          display: none;
        }

        &__right-button {
          visibility: visible;
          display: block;
          width: 32px;
          height: 32px;
          cursor: pointer;
          padding: 0;

          &:hover {
            .icon-marmita {
              @include colorize-icon($text-color-super-darken);
            }
          }

          .icon-marmita {
            width: 32px;
            height: 32px;
            transform: scale(1);

            @include colorize-icon($text-color);

            svg {
              transition: fill $animation-duration $ease-out-circ;
            }
          }
        }
      }
    }
  }

  .btn-icon#{&}__header-back-button {
    left: initial;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    // overriding selector ".nav-header--primary .btn-icon .icon-marmita svg"
    &--transparent.dish-modal__header-back-button {
      // see https://jakearchibald.com/2014/visible-undoes-hidden/
      visibility: visible;
      background-color: color.adjust($text-color-super-darken, $alpha: 0.6);

      svg {
        color: white;
        fill: white;
      }
    }
  }
}
