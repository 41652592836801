@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-discount-tag {
  margin-bottom: 16px;
  border-radius: $border-radius-default;
  display: inline-flex;

  &__title {
    font-size: rem(10);
    line-height: rem(12);
    font-weight: 500;
    border: 0;
    background-color: initial;
    padding: 6px;

    @include mq('tablet') {
      font-size: rem(12);
    }
  }

  &__description {
    font-size: rem(12);
    color: $text-color;
    left: -5px;
    font-weight: 500;
  }
}

.dish-discount-tag-tooltip {
  &__container {
    max-width: 320px;
    font-size: rem(14);
    transform: translateY(-10px);
  }

  &__content {
    font-size: rem(12);
    color: initial;
  }
}
