@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.ph-restaurant-menu {
  padding: $padding-default;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;

  @include mq('desktop-s') {
    width: calc(50% - 20px);
    padding: 20px;
    border: 1px solid #eee;
    border-radius: $border-radius-default;
    margin: 30px 0 10px;
  }

  &-container {
    @include mq('desktop-s') {
      padding: 0 $padding-default-desktop;
    }

    @include mq('desktop') {
      max-width: 1366px;
      margin: auto;
    }

    @include mq('desktop-wide') {
      padding-right: 30px;
    }

    .ph-restaurant-menu-title {
      display: none;
      width: 250px;
      height: 27px;
      margin-top: 40px;

      @include mq('desktop-s') {
        display: flex;
      }
    }
  }

  &-wrapper {
    @include mq('desktop-s') {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .lines {
    width: 80%;

    & > * {
      margin: 5px 0;
    }

    *:nth-child(1) {
      width: 65%;
    }

    *:nth-child(2) {
      width: 75%;
    }

    *:nth-child(3) {
      width: 70%;
    }

    *:nth-child(4) {
      width: 30%;
      margin: 15px 0 0;
    }
  }

  &__rect {
    width: 90px;
    height: 90px;
  }
}
