@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-info-product-item {
  &__quantity {
    font-size: rem(12);
    line-height: rem(16);
    color: $gray-color-700;
    padding: 4px 10px;
    width: 21px;
    height: 16px;
    background-color: $light-grey-color-100;
    border-radius: 4px;
    margin-right: 8px;
  }

  &__name {
    color: $text-color;
    font-size: rem(14);
    line-height: rem(18);
  }
}
