@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.ph-address-btn {
  height: 78px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $light-grey-color-100;
  min-height: 80px;
  display: grid;
  grid-template: 1fr 1fr / 60px 1fr;
  grid-template-areas:
    'icon title'
    'icon subtitle';
  gap: 0 10px;

  &__icon {
    grid-area: icon;
    height: 30px;
    width: 30px;
    justify-self: center;
    align-self: center;
    border-radius: 4px;
  }

  &__title {
    grid-area: title;
    height: 16px;
    align-self: end;
    width: 60%;
    border-radius: 4px;
  }

  &__subtitle {
    grid-area: subtitle;
    height: 12px;
    margin-top: 10px;
    width: 80%;
    border-radius: 4px;
  }
}
