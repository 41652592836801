@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchandise-tags-info-content {
  padding: 20px 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin: 0;
    font-size: rem(14);
    line-height: rem(18);
    color: $gray-color-700;
  }

  &__tags {
    padding: 16px 0;
    width: 100%;
  }

  &__description {
    margin: 0 auto;
    padding: 6px 0 12px;
    width: 270px;
    font-size: rem(12);
    line-height: rem(14);
    color: $text-color-lighten;
    text-align: center;

    @include mq('desktop-s') {
      font-size: rem(12);
      line-height: rem(16);
      padding: 0;
    }
  }
}
