@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-scale-price {
  margin-top: 12px;

  &__content {
    display: grid;
    grid-template-areas: 'scale divider original';
    grid-auto-columns: max-content 1px max-content;
    column-gap: 24px;

    &::before {
      content: '';
      grid-area: divider;
      width: 1px;
      background-color: $light-grey-color-300;
    }
  }

  &__scale {
    grid-area: scale;
  }

  &__price {
    font-weight: 500;
    font-size: rem(20);
    line-height: rem(22);
    margin: 0;
    transition: 200ms ease-in-out;

    &--scale {
      color: $green-color;
    }

    &--original {
      color: $gray-color-700;
      margin-right: 4px;
    }

    &--disabled {
      color: $light-grey-color-300;
      text-decoration: line-through;
    }
  }

  &__original {
    grid-area: original;
  }

  &__original-wrapper--inline {
    display: inline-flex;
    align-items: flex-end;
  }

  &__original-info {
    font-weight: 400;
    font-size: rem(12);
    line-height: rem(16);
    color: $text-color;
    transition: 200ms ease-in-out;

    &--disabled {
      color: $light-grey-color-300;
    }
  }

  &__discount {
    display: flex;
    align-items: center;
    margin-top: 2px;
  }

  @mixin scale-price-discount-font {
    font-weight: 500;
    font-size: rem(10);
    line-height: rem(12);
  }

  &__discount-tag {
    padding: 2px 4px;
    border-radius: 4px;
    background-color: $green-color;
    color: $white-color;
    display: inline-flex;
    margin: 0;
    transition: 200ms ease-in-out;

    @include scale-price-discount-font;

    &--disabled {
      background-color: $light-grey-color-300;
      color: $white-color;
    }
  }

  &__discount-price {
    color: $text-color-lighten;
    text-decoration: line-through;
    margin: 0 0 0 4px;
    transition: 200ms ease-in-out;

    @include scale-price-discount-font;

    &--disabled {
      color: $light-grey-color-300;
    }
  }
}
