@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-card-cold-beverage-tag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(255 255 255 / 70%);
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 17px;
    height: 18px;
  }
}
