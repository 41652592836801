@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cart-shuffle-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 $padding-default;

  @include mq('desktop-s') {
    padding: $padding-default 50px;
    max-width: 480px;
  }

  &__title {
    font-size: rem(24);
    line-height: rem(30);
    font-weight: 500;
    color: $text-color-super-darken;
    margin-bottom: 14px;
  }

  &__subtitle {
    font-size: rem(16);
    line-height: rem(20);
    font-weight: 400;
    color: $text-color-super-darken;
    margin-bottom: 50px;
  }
}
