@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.error-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 64px 20px;

  &__title {
    font-size: rem(16);
    line-height: rem(22);
    font-weight: 400;
    margin: 0;

    @include mq('desktop') {
      font-size: rem(18);
      line-height: rem(24);
    }
  }

  &__description {
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 300;
    color: $text-color;
    margin: 8px auto 32px;
    max-width: 340px;

    @include mq('desktop') {
      font-size: rem(16);
      line-height: rem(22);
    }
  }

  &__illustration {
    margin-bottom: 36px;
  }
}
