@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-maps {
  &__container {
    height: 100%;
    position: relative;
    transition: height 300ms $ease-out-quad;
  }

  &__map {
    height: 100%;
    position: relative;
    transition: height 300ms $ease-out-quad;
  }

  &__pin {
    position: absolute;
    width: 36px;
    height: 44px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 15px));

    @include colorize-icon($primary-color);

    svg {
      transform: translateX(-0.3px);
      transition: transform ease-in 150ms;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 11px;
      height: 5px;
      border-radius: 100%;
      bottom: 0;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -3px);
      background-color: #3e3e3e;
      opacity: 0.3;
    }

    &--active {
      @include colorize-icon(#3e3e3e);

      svg {
        transform: translate(-0.5px, -5px);
      }
    }
  }

  &__geo {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 13px;
      height: 13px;
      background-color: #557aff;
      border: 3px solid #fff;
      border-radius: 100%;
      box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
      transform: translate(-50%, -50%);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 100%;
      z-index: -1;
      width: 43px;
      height: 43px;
      background-color: rgba(#557aff, 0.1);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__focus-button {
    position: absolute;
    right: 25px;
    bottom: 130px;
    width: 52px;
    height: 52px;
    margin: 0;
    box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
    transition: all ease-in 150ms;
    cursor: pointer;

    .icon-marmita {
      width: 24px;
      height: 24px;

      @include colorize-icon($black-color);
    }

    &:hover {
      background: #fff;
      box-shadow: 0 4px 4px rgb(0 0 0 / 20%);

      .icon-marmita {
        @include colorize-icon($text-color-super-darken);
      }
    }
  }

  &__submit {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 40px);
    max-width: 375px;
    transition: all ease-in 150ms;
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);

    &[disabled] {
      background: #f2f2f2;
      color: #a6a6a6;
      opacity: 1;
    }
  }

  &__modal {
    text-align: center;
    padding: 0 $padding-default;

    h4 {
      color: #2b2b2b;
      font-size: em(18);
      margin-top: 0;
    }

    p {
      color: #a8a8a8;
    }
  }
}
