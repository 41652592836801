@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-action {
  .dish-tooltip {
    align-items: center;
    background: #fff;
    border-top: 2px solid $light-grey-color;
    display: flex;
    justify-content: flex-end;
    padding: $padding-default $padding-default;
    height: 80px;
    width: 100%;
  }

  &__add-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: rem(14);

    @include mq('mobile-l') {
      flex-direction: row;
    }
  }

  &__remove-text {
    width: 100%;
    text-align: center;
  }

  &__update-wrapper,
  &__add-wrapper {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    @include mq('desktop-s') {
      justify-content: flex-end;
    }

    .btn.btn--full-width,
    .btn--default {
      width: 100%;
      margin: 0;
      text-align: center;

      @include mq('desktop-s') {
        width: 240px;
        max-width: 240px;
      }
    }
  }

  &__toastr-cta {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
  }

  &__remove-button {
    border: 1px solid $light-grey-color;
  }

  &__counter {
    display: inline-flex;
    border: 1px solid $light-grey-color-300;
    border-radius: $border-radius-default;
    margin-right: 10px;

    @include mq('desktop-s') {
      margin-right: 15px;
    }
  }
}
