@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
@import '~normalize.css/normalize.css';
@import '~react-loader-spinner/dist/loader/css/react-spinner-loader.css';
@include meta.load-css('../styles/fonts');

// This properties is used to have a better font rendering
html {
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

.scroll-smooth {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include mq('tablet', max) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

::selection {
  color: #fff;
  background: $primary-color;
}

// Initial attributes
body {
  font-family: $font-family;
  overflow-x: hidden;
  font-size: $font-size-default + px;
  height: 100%;
  scroll-behavior: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
}

.landing-loading {
  opacity: 0;
}

.no-scroll {
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
}

.headers {
  &--desktop {
    display: none;

    @include mq('desktop-s') {
      display: block;
    }
  }

  &--mobile {
    display: block;
    min-height: $navheader-height;

    @include mq('desktop-s') {
      display: none;
    }
  }
}

:focus {
  // TODO define focus styles when its needed
  outline: 0;
}

a {
  cursor: default;

  @include mq('desktop-s') {
    cursor: pointer;
  }
}

ul {
  margin: 0;
  padding: 0;

  & > li {
    list-style: none;
  }
}

.main-layout {
  transition: 500ms;
  background: #fff;
  padding: 0 0 $tab-bar-height;
  overflow-x: hidden;

  &#{&}--full-primary {
    min-height: 100vh;
    background: $primary-color;
  }

  @include mq('desktop-s') {
    padding: 0;
    overflow-x: unset;
  }

  &--with-home-button {
    padding: 0 0 $tab-bar-height + $home-button-height;
  }
}

.with-padding {
  padding: 0 20px;
}

.page-wrapper {
  .nav-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    & ~ * {
      margin-top: 70px;
    }
  }
}

// Ignore background coloring on click on a button or link
a,
button {
  -webkit-tap-highlight-color: transparent;
}

b,
strong {
  font-weight: bold;
}

.accessibility-hide {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  display: block;
  text-indent: -999999px;
}

// Animation
.ph-loading {
  animation-duration: 2s !important;
  animation-fill-mode: forwards !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
  animation-name: placeholder-animate !important;
  background: $text-color-super-darken; // Fallback
  background: linear-gradient(to right, #f3f3f3 2%, #e9e8e8 18%, #f3f3f3 33%) !important;
  background-size: 1300px !important;
  border-radius: 2px;
  min-height: 10px;

  &--lighten {
    opacity: 0.6;
  }
}

.display-none {
  display: none;
}

.desktop-container {
  @include mq('desktop-s') {
    width: 100%;
    max-width: $desktop-container;
    padding: 0 $padding-default-desktop;
    margin: 50px auto 0;
  }
}

.app-container {
  padding: $padding-default;

  @include mq('desktop-s') {
    padding: 0;
  }
}
