@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.complete-address {
  margin: 0 auto;
  color: $black-color;
  padding: $padding-default;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 560px));
  grid-template-rows: max-content;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-color: $light-grey-color-300 transparent;

  &::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: $light-grey-color-300;
  }

  @include mq('tablet') {
    padding: $padding-default $padding-default 60px $padding-default;
  }

  &__address-info {
    margin-bottom: 20px;

    @include mq('tablet') {
      display: flex;
      align-items: baseline;
    }
  }

  &__street-label {
    margin: 0 0 5px;
    color: $black-color;
    font-size: rem(13.37);

    @include mq('tablet') {
      font-size: rem(16);
      margin: 0 10px 0 0;
    }
  }

  &__state-label {
    margin: 0;
    font-size: rem(13.37);
    color: $medium-grey-color;

    @include mq('tablet') {
      font-size: rem(14);
    }
  }

  &__form {
    .label__name {
      font-size: rem(12) !important;
    }

    .form-input__field {
      font-size: rem(15);
    }

    .native-select__field {
      font-size: rem(15);
    }
  }

  &--city-state {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 14px;
  }

  &--district-street {
    display: grid;
    grid-template-columns: 1fr;

    @include mq('tablet') {
      grid-template-columns: 30% 1fr;
      grid-gap: 14px;
    }
  }

  &--number-complement {
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-gap: 14px;

    @include mq('tablet') {
      margin-bottom: 15px;
    }
  }

  &--reference-point {
    @include mq('tablet') {
      margin-bottom: 20px;
    }
  }

  &--favorites {
    margin-bottom: 15px;

    @include mq('tablet') {
      margin-bottom: 40px;
      max-width: 350px;
    }

    p {
      color: $black-color;
      margin: 0 0 16px;
      font-size: rem(14);
    }

    .btn {
      background: $light-grey-color-100;
      border-radius: $border-radius-default;
      font-size: rem(14);
      height: 40px;
      border: 0;
      font-weight: 400;

      .icon-marmita {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    path[fill='#3e3e3e'] {
      fill: $text-color;
    }

    path[stroke='#3e3e3e'] {
      stroke: $text-color;
    }
  }

  &--favorites-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .btn__label {
      flex-grow: initial;
    }
  }

  &__favorite {
    &.btn {
      position: relative;

      &::before,
      &::after {
        pointer-events: none;
        width: 100%;
        height: 100%;
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        transform: scale(0);
        border-radius: 0;
        transition: transform 200ms ease, border-radius 100ms ease 100ms;
      }

      &::before {
        border-bottom: 1px solid $primary-color;
        border-left: 1px solid $primary-color;
        transform-origin: 0 100%;
      }

      &::after {
        border-top: 1px solid $primary-color;
        border-right: 1px solid $primary-color;
        transform-origin: 100% 0%;
      }
    }
  }

  &__favorite--active {
    &.btn {
      color: $primary-color;

      &::after,
      &::before {
        transform: scale(1);
        border-radius: $border-radius-default;
      }
    }

    &,
    path {
      transition: all 300ms ease-in-out;
    }

    path[fill='#3e3e3e'] {
      fill: $primary-color;
    }

    path[stroke='#3e3e3e'] {
      stroke: $primary-color;
    }
  }

  &--save-btn {
    margin-bottom: 30px;
  }
}
