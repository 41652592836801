@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-set-confirmation {
  display: none;
  opacity: 0;
  height: 0;
  grid-template-columns: minmax(auto, 350px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 300ms ease;
  overflow: hidden;

  &--visible {
    opacity: 1;
    display: grid;
    height: auto;
  }

  &__title {
    display: flex;
    flex-direction: column;
    font-size: rem(16);
    line-height: rem(22);
    margin-bottom: 20px;
    font-weight: 300;

    @include mq('tablet') {
      font-size: rem(18);
      margin-bottom: 56px;
    }

    &--name {
      @include mq('tablet') {
        font-weight: 500;
      }
    }
  }

  &__subtitle {
    font-size: rem(14);
    line-height: rem(20);
    font-weight: lighter;
    margin-bottom: 40px;

    @include mq('tablet') {
      margin-bottom: 46px;
    }
  }

  &__accept-btn {
    margin-bottom: 20px;
  }
}
