@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.ph-restaurant-card {
  border: 1px solid $light-grey-color-100;
  display: grid;
  transition: 200ms;
  height: 90px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  text-decoration: none;
  width: 100%;
  grid-template-columns: 60px 1px 1fr;
  grid-template-areas: 'figure divider content';
  grid-column-gap: 12px;

  @include mq('desktop-s') {
    height: 130px;
    grid-template-columns: 85px 1px 1fr;
    grid-column-gap: 20px;
    padding: $padding-default;
  }

  &::before {
    grid-area: divider;
    content: '';
    width: 1px;
    background-color: $light-grey-color-100;
  }

  &__figure {
    grid-area: figure;
  }

  &__content {
    grid-area: content;
    display: grid;
    grid-template-rows: 1fr 20px;
  }

  &__header {
    width: 85%;
    height: 18px;
    margin-bottom: 6px;
  }

  &__info {
    width: 65%;
    height: 14px;
  }

  &__footer {
    width: 45%;
    height: 14px;
  }
}
