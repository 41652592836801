@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-number {
  z-index: 2;
  position: absolute;
  opacity: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content;
  grid-row-gap: 5px;
  background-color: white;
  transition: top 300ms $ease-out-quad, opacity 300ms $ease-out-quad;

  @include mq('tablet') {
    grid-row-gap: 0;
  }

  &--visible {
    top: 0;
    opacity: 1;
  }

  &__back-btn {
    width: 26px;
    height: 26px;
    margin: 0;
    margin-top: 24px;
  }

  &__form-wrapper {
    display: grid;
    grid-template-rows: 22px;
    grid-row-gap: 20px;
    text-align: center;
    margin-bottom: auto;

    @include mq('tablet') {
      grid-template-rows: 44px;
      width: 330px;
      margin: auto;
    }
  }

  &__title {
    color: $text-color-super-darken;
    font-size: rem(16);
    line-height: 22px;
    margin: auto;
  }
}
