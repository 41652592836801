@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchandise-tags {
  border-top: 1px solid $light-grey-color-100;
  border-bottom: 1px solid $light-grey-color-100;
  padding: 6px 0;
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;

  @include mq('desktop-s') {
    padding-right: 12px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }
}
