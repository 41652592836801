@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-login {
  display: grid;
  grid-template-rows: 20px 20px 50px;
  grid-row-gap: 5px;
  text-align: center;
  align-self: end;

  @include mq('mobile', max) {
    grid-template-rows: 20px 40px 50px;
  }

  &__title {
    font-size: rem(16);
    line-height: 20px;
    font-weight: 400;
    color: #595756;
  }

  &__description {
    color: $medium-grey-color;
    font-size: rem(14);
    line-height: 17px;
    font-weight: 300;
  }

  .btn {
    margin: 0;
  }
}
