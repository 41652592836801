@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-long-description {
  padding: 18px 16px 36px;

  @include mq('tablet') {
    padding: 18px 44px 36px;
  }

  @include mq('desktop-s') {
    padding: 0;
    cursor: pointer;
    margin-bottom: 24px;
  }

  &__title {
    font-size: rem(16);
    line-height: rem(20);
    color: $text-color-super-darken;
    margin: 0;
    margin-bottom: 16px;
    font-weight: 500;

    @include mq('desktop-s') {
      display: none;
    }
  }

  &__text {
    font-size: rem(12);
    line-height: rem(20);
    color: $text-color;
    margin: 0;
    white-space: break-spaces;

    @include mq('desktop-s') {
      line-height: rem(16);

      &--collapsed {
        max-height: rem(32);
        overflow: hidden;
        display: inline-block;
        position: relative;

        &::after {
          content: var(--see-more);
          display: inline-block;
          width: 40px;
          position: relative;
          z-index: 999;

          /* a big box-shadow to hide the span element used for the ellipsis */
          box-shadow: 40px 0 0 #fff, 80px 0 0 #fff, 120px 0 0 #fff, 160px 0 0 #fff;
          color: $primary-color;
          font-size: rem(12);
          background: #fff; /* white background to cover the text behind */
          margin-left: 2px;
          text-transform: lowercase;
        }

        span {
          position: absolute;

          /* position at the bottom right */
          top: rem(16); /* height of one line */
          right: 0;
          padding: 0 3px;
          background: #fff; /* white background to cover the text behind */

          &::before {
            content: '...'; /* the dots */
            color: $text-color;
          }

          &::after {
            content: var(--see-more);
            color: $primary-color;
            font-size: rem(12);
            text-transform: lowercase;
          }
        }
      }
    }
  }
}
