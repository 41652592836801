@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-number {
  &__form {
    display: grid;
    grid-row-gap: 20px;
    grid-template-rows: 22px 74px 20px 50px;
    width: 100%;
    margin: auto;

    @include mq('desktop') {
      grid-row-gap: 40px;
      grid-template-rows: 22px 65px 20px 50px;
    }

    button {
      margin: 0;
    }

    .form-input {
      padding: 0;
      margin: 0;
      min-height: 0;
    }
  }

  &__address-info {
    display: grid;
    grid-row-gap: 2px;

    p {
      margin: auto;
    }

    .street-name {
      color: $text-color;
      font-size: rem(16);
      line-height: 22px;
    }

    .district {
      color: $text-color-lighten;
      font-size: rem(14);
      line-height: 20px;
    }
  }

  &__input {
    width: 110px;
    margin: auto;
  }

  &__empty-number {
    color: #a6a6a5;
    font-size: rem(12);
    line-height: 16px;
    margin: auto;
  }

  &__checkbox-label {
    display: grid;
    grid-template-columns: 140px 20px;
    grid-column-gap: 15px;
    margin: auto;
  }
}
