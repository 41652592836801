@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-card__tag {
  display: inline;
  font-size: rem(12);
  color: #2e6788;
  font-weight: lighter;
  line-height: rem(16);

  .icon-marmita {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    transform: translateY(1px);
  }

  svg {
    fill: #2e6788;
  }
}

.dish-card--vertical {
  .dish-card__tag {
    display: none;

    @include mq('mobile-l') {
      display: inline;
    }

    &--mini {
      display: block;
      position: absolute;
      right: 12px;
      bottom: 6px;

      @include mq('mobile-l') {
        display: none;
      }

      .dish-card__tag-description {
        display: none;
      }

      .icon-marmita {
        margin-right: 0;
      }
    }
  }
}
