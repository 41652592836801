@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-card-wrapper {
  position: relative;
  width: 100px;
  cursor: pointer;

  @include mq('tablet') {
    width: 122px;
  }

  .rupture-anticipation-quick-switch {
    right: 4px;
    top: 58px;
    position: absolute;

    @include mq('desktop') {
      top: 100px;
    }
  }

  .quick-add {
    position: absolute;
    display: flex;
    top: 58px;
    right: 0;

    @include mq('tablet') {
      top: 74px;
      right: 8px;
    }
  }
}
