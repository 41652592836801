@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-action {
  &__add-button {
    margin: 0;
  }

  .marmita-counter {
    height: 50px;
    border: 1px solid $light-grey-color-100;
    border-radius: 4px;
    padding: 0 4px;

    &__value {
      flex: 1;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(20);
      color: $text-color-darken;
      height: auto;
    }
  }

  &__merchant-closed-title {
    margin: 0;
    font-size: rem(18);
    line-height: rem(24);
    color: $text-color-super-darken;
    font-weight: 400;
  }

  &__merchant-closed-message {
    margin: 12px 0 0;
    font-size: rem(14);
    line-height: rem(18);
    color: $text-color;
    font-weight: 400;
  }
}
