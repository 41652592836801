@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-invalid-dialog {
  padding: $padding-default;
  width: 400px;

  &__address,
  &__text {
    font-size: rem(18);
    line-height: em(22);
  }

  &__text {
    margin-top: 0;
    color: $medium-grey-color;
    margin-left: 15px;
  }

  &__title {
    font-size: rem(22);
    font-weight: 500;
    color: #595756;
  }

  &__address {
    color: #595756;
    text-transform: capitalize;
  }

  @include mq('tablet', max) {
    width: 100%;
  }
}

.address-invalid-wrapper {
  display: flex;
  align-items: flex-start;
}

.address-invalid-from {
  &__icon {
    @include colorize-icon($medium-grey-color);
  }
}

.address-invalid-to {
  &__icon {
    @include colorize-icon($primary-color);
  }
}

.address-invalid-from,
.address-invalid-to {
  &__icon {
    background: #fff;
    padding: 2px;
    width: 28px;
    height: 28px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.address-invalid-from + .address-invalid-to {
  .address-invalid-to__icon {
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      width: 3px;
      height: 35px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      background: $cream-color;
    }
  }
}
