@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.report-modal {
  width: 100%;
  padding: 16px 32px;

  @include mq('desktop-s') {
    width: 864px;
  }

  button {
    cursor: pointer;
  }

  &__back {
    padding: 0;
    text-decoration: underline;
  }

  &__content {
    margin: 0;
    text-align: center;

    @include mq('desktop-s') {
      margin: 32px 0;
      text-align: left;
    }

    li {
      padding-left: 0;
      padding-right: 4px;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__confirm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 32px 0 16px;
    gap: 16px;

    @include mq('desktop-s') {
      padding: 0;
    }
  }
}
