@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$four-to-three-ratio: 0.75;

.dish-content {
  display: grid;
  grid-template-rows: 60px minmax(auto, calc(var(--screen-y) - 140px)) 80px;
  grid-template-areas:
    'header'
    'scroll'
    'action';
  max-height: var(--screen-y);

  &#{&}--no-image {
    grid-template-areas:
      'header'
      'scroll'
      'action';
    grid-template-columns: auto;
    max-width: 600px;
  }

  &__info-serves {
    padding-top: 10px;
  }

  @include mq('desktop-s') {
    width: fit-content;
    grid-template: 40px auto 80px / 1fr 1fr;
    grid-template-areas:
      'image header'
      'image scroll'
      'image action';
    height: $four-to-three-ratio * 60vw;
    max-width: 1200px;
    max-height: 580px;
    min-height: auto;
    border-radius: 4px;

    &#{&}--no-image {
      grid-template-areas:
        'header'
        'scroll'
        'action';
      height: 80vh;
      min-width: 600px;
    }

    &:not(#{&}--no-image) {
      @include mq('desktop', 'min') {
        min-width: 1200px;
      }
    }
  }

  @include mq('desktop') {
    grid-template-columns: 1fr 1fr;
  }

  &__container {
    padding-left: 20px;
    padding-right: 20px;
    display: block;

    button {
      padding: 0;
    }

    @include mq('desktop-s') {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &__img-container {
    grid-area: image;
    display: flex;
    flex-flow: nowrap row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  &__img {
    max-width: 100%;
    z-index: $dish-image-z-index;
    max-height: 40vh;
    padding: 8px;

    @include scroll-parallax;

    @include mq('desktop-s') {
      max-height: 100%;
      transform: none;
    }
  }

  &__img-bg {
    width: 100%;
    height: 40vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__header {
    grid-area: header;
  }

  &__scrolling {
    overflow-y: auto;

    @include mq('desktop-s') {
      @include custom-scrollbar;

      &--loop-spacing {
        // spacing for loop fixed message
        padding-bottom: 88px;
      }
    }
  }

  &__action {
    grid-area: action;
    z-index: $dish-action-buttons-z-index;

    @include mq('desktop-s', 'max') {
      bottom: 0;
      width: 100%;
      height: 80px;
    }
  }

  &__title-wrapper {
    > div > div:first-child {
      @include mq('desktop-s', 'max') {
        padding-bottom: 0 !important;
      }
    }
  }

  &__title {
    font-size: rem(18);
    font-weight: 500;
    color: $text-color-super-darken;
    background: #fff;
    z-index: 30;
    width: 100%;
    margin: 0;
    padding-bottom: 0;

    .promotional-tag {
      margin: 12px 0 0;
    }

    &--sticky {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 25px;
    }

    @include mq('desktop-s', 'max') {
      border-bottom: none;
      position: relative !important;
      top: initial !important;
      left: initial !important;
      width: auto !important;
      margin-top: 10px;
      padding: 2px $padding-default;
    }
  }

  &__details {
    display: inline-block;
    font-size: rem(14);
    padding-top: 10px;
    margin: 0;
    font-weight: 300;
    line-height: 1.22;
    color: $text-color;
    white-space: pre-line;
    word-break: break-all;
  }

  .dish-restaurant {
    margin-bottom: 12px;
  }

  .dish-discount-tag {
    margin-top: 12px;
  }

  &__merchandise-tags {
    margin: 12px 0 24px;
  }
}

.dish-warnings {
  color: $text-color-lighten;
  font-size: rem(15);
  margin-top: 15px;

  &__title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__item {
    display: inline-block;
    font-weight: 300;
  }
}

.dish-content-tag-tooltip {
  &__container {
    max-width: 320px;
    font-size: rem(14);
    transform: translateY(30px);
  }

  &__content {
    font-size: rem(12);
    color: initial;
  }
}
