@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-modal {
  height: 100%;
  padding: 0;

  @include mq('tablet') {
    height: calc(100vh - 100px);
    max-height: 584px;
    width: 694px;
  }

  .marmita-modal {
    &__inner-content {
      display: grid;
    }
  }

  .btn-address {
    margin: 0;
  }
}

.address-modal-overlay {
  z-index: 99999;

  & > .marmita-modal__content--after-open {
    border-radius: 0;

    @include mq('tablet') {
      top: 80px;
      transform: translateX(-50%);
      border-radius: $border-radius-default;
    }
  }
}
