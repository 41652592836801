/**
Official fonts for iFood products
All assets are inside /static/fonts
"Titulos" for headings, and "Textos" for paragraphs
@see /static/fonts/_LeiaMe.pdf (in pt-BR) for more info

Did not declare fonts Thin and ExtraBold because they are rarely used
sometimes without meaning to use it, and it also adds network data usage
which we could avoid.
There is also a Variable Font
since browser support is yet experimental it was omitted
https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Fonts/Variable_Fonts_Guide
**/

@font-face {
  font-family: 'iFood RC Titulos';
  src:
    url('/static/fonts/iFoodRCTitulos-Light.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTitulos-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iFood RC Titulos';
  src:
    url('/static/fonts/iFoodRCTitulos-Medium.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTitulos-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iFood RC Titulos';
  src:
    url('/static/fonts/iFoodRCTitulos-Regular.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTitulos-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iFood RC Titulos';
  src:
    url('/static/fonts/iFoodRCTitulos-Bold.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTitulos-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iFood RC Textos';
  src:
    url('/static/fonts/iFoodRCTextos-Light.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTextos-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iFood RC Textos';
  src:
    url('/static/fonts/iFoodRCTextos-Regular.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTextos-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iFood RC Textos';
  src:
    url('/static/fonts/iFoodRCTextos-Medium.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTextos-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iFood RC Textos';
  src:
    url('/static/fonts/iFoodRCTextos-Bold.woff2') format('woff2'),
    url('/static/fonts/iFoodRCTextos-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
