@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-search-list {
  display: grid;
  grid-auto-rows: auto;
  gap: 10px;

  .ph-address-btn {
    border: none;
    border-bottom: 1px solid #f7f7f7;
  }

  &__not-found {
    .btn-address__info--label {
      color: $primary-color;
    }
  }

  .btn-address--simple {
    border-color: transparent;

    &::before,
    &::after {
      pointer-events: none;
      width: 100%;
      height: 100%;
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      transform: scale(0);
      border-radius: 0;
      transition: transform 1000ms ease, border-radius 100ms ease 900ms;
    }

    &::before {
      border-bottom: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
      transform-origin: 0 100%;
    }

    &::after {
      border-top: 1px solid $primary-color;
      border-right: 1px solid $primary-color;
      transform-origin: 100% 0%;
    }
  }

  .btn-address--is-active {
    &::after,
    &::before {
      transform: scale(1);
      border-radius: $border-radius-default;
    }
  }
}
