@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$address-button-transition: all 200ms ease-in-out;

.btn-address {
  width: 100%;
  min-height: 40px;
  padding: 15px;
  border: 0;
  position: relative;
  color: $text-color;
  background: transparent;
  overflow: hidden;
  text-decoration: none;
  transition: $address-button-transition;
  height: auto;
  opacity: 1;
  visibility: visible;
  cursor: pointer;

  button {
    border: 0;
    background: transparent;

    @include mq('tablet') {
      cursor: pointer;
    }
  }

  &--full-size {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &--disabled {
    pointer-events: none;
    cursor: wait;
    opacity: 0.5;
  }

  @include mq('tablet') {
    &:hover,
    &:active {
      .btn-address__icon--right {
        fill: $text-color-super-darken;
      }

      color: $text-color-super-darken;

      .icon-marmita {
        &--location,
        &--house,
        &--coffee-cup,
        &--synchronize-arrow {
          path[stroke='#3e3e3e'] {
            stroke: $text-color-super-darken;
          }

          path[fill='#3e3e3e'] {
            fill: $text-color-super-darken;
          }

          .shadow-bg {
            opacity: 0.2;
          }
        }
      }
    }
  }

  .icon-marmita svg {
    path,
    g {
      transition: $address-button-transition;
    }
  }

  &--default {
    border-radius: 8px;
    border: 1px solid $light-grey-color-100;

    @include mq('tablet') {
      &:hover,
      &:active {
        color: $text-color-super-darken;
        box-shadow: 0 4px 10px rgb(0 0 0 / 5%), 0 4px 16px rgb(0 0 0 / 8%);
        border-color: transparent;
      }
    }
  }

  &--simple {
    .icon-marmita path[stroke='#3e3e3e'] {
      stroke: $text-color-lighten;
    }

    .icon-marmita path[fill='#3e3e3e'] {
      fill: $text-color-lighten;
    }

    .btn-address__info--description {
      color: $text-color-lighten;
    }
  }

  &--is-active {
    border-color: $primary-color;
    color: $text-color-super-darken;

    .btn-address__icon--right {
      fill: $text-color-super-darken;
    }

    .icon-marmita {
      &--location,
      &--house,
      &--coffee-cup,
      &--synchronize-arrow {
        path[stroke='#3e3e3e'] {
          stroke: $text-color-super-darken;
        }

        path[fill='#3e3e3e'] {
          fill: $text-color-super-darken;
        }

        .shadow-bg {
          opacity: 0.2;
        }
      }
    }
  }

  &--is-open-actions {
    .btn-address__icon--left {
      margin-right: 20px;
      width: 90px;
      min-width: 90px;
    }

    .icon-marmita {
      &--more-dots {
        fill: $primary-color;
      }
    }

    .btn-address {
      &__icon-actions {
        transform: translateX(0);
      }

      &__icon-action {
        path {
          color: $primary-color;
          fill: $primary-color;
        }

        &:hover {
          transform: scale(1.05);

          path {
            color: $primary-dark-color;
            fill: $primary-dark-color;
          }
        }
      }
    }
  }

  &__description {
    font-size: 0.88rem;
    line-height: 20px;
  }

  &__container {
    display: flex;
  }

  &__info {
    gap: 4px;
    display: grid;
    text-align: left;
    align-content: center;
    flex-grow: 1;
    transition: $address-button-transition;
    word-break: break-word;

    &--description {
      line-height: 18px;
      font-size: 14px;
      font-weight: 100;
    }
  }

  &__icon {
    &--left {
      display: grid;
      align-self: center;
      width: 50px;
      transition: $address-button-transition;
      min-width: 50px;
      max-height: 40px;

      .icon-marmita {
        height: 25px;
        width: 25px;
      }
    }

    &--right {
      width: 35px;
      min-width: 35px;
      height: 35px;
      padding: 0 5px;
      display: flex;
      align-content: flex-start;
      transform: translate(10px);
      fill: $text-color-lighten;

      button {
        height: 25px;
      }
    }
  }

  &__delete-confirmation {
    opacity: 0;
    visibility: hidden;
    background: transparent;
    border: 0;
    cursor: pointer;
    height: 0;
    transition: all 200ms ease;

    .icon-marmita {
      height: 30px;
      width: 30px;
      transition: transform 200ms ease;

      & svg {
        path,
        g {
          transition: none;
        }
      }

      &--error:hover {
        transform: scale(1.1);

        @include colorize-icon($primary-color);
      }

      &--confirm:hover {
        transform: scale(1.1);

        @include colorize-icon($green-color);
      }
    }
  }

  &__icon-actions {
    grid-gap: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    transform: translateX(-50px);
    transition: $address-button-transition;

    &--delete-confirming {
      .btn-address__delete-confirmation {
        display: flex;
        visibility: visible;
        opacity: 1;
        height: 40px;
      }

      .btn-address--btn-actions {
        opacity: 0;
        height: 0;
      }
    }
  }

  &--btn-actions {
    background: transparent;
    border: 0;
    cursor: pointer;
    height: 40px;
    transition: all 200ms ease;
  }

  &--hidden {
    height: 0;
    min-height: 0;
    padding: 0 15px;
    opacity: 0;
  }

  &--display-none {
    display: none;
  }
}
