@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-maps-header {
  position: absolute;
  width: 100%;
  display: grid;
  top: 0;
  left: 0;
  grid-template-columns: 50px 1fr 50px;
  padding: 27px 20px;
  background:
    linear-gradient(
      0deg,
      rgb(255 255 255 / 15%) 0%,
      rgb(255 255 255 / 80%) 25%,
      rgb(255 255 255 / 100%) 100%
    );

  @include mq('tablet') {
    padding: 30px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__title {
    font-size: rem(13);
    color: #595756;

    @include mq('tablet') {
      font-size: rem(16);
    }

    &--placeholder {
      width: 160px;
      height: 16px;
      margin-bottom: 5px;
    }
  }

  &__subtitle {
    font-size: rem(11);
    color: #a6a6a6;

    @include mq('tablet') {
      font-size: rem(14);
    }

    &--placeholder {
      width: 250px;
      height: 14px;
    }
  }

  &__back-btn {
    margin-left: 11px;
  }
}
