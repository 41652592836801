@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-low-stock-alert {
  display: flex;
  align-items: center;
  margin: 15px 0;
  height: 36px;
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: $bubblegum;
  border-radius: 4px;

  @include mq('tablet') {
    margin: 12px 0 18px;
  }

  &__warning-icon {
    margin-right: 8px;
  }

  &__text {
    color: $brand-light-color;
    margin: 0;
    font-size: rem(14);
    line-height: rem(16);
    font-weight: 400;

    @include mq('tablet') {
      font-size: rem(13);
    }
  }
}
