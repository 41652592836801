@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$product-detail-header-desktop-height: 100px;

.product-detail-header {
  top: 0;
  left: 0;
  right: 0;
  background-color: $light-grey-color-100;
  z-index: 1;

  @include mq('desktop-s') {
    display: flex;
    gap: 30px;
    align-items: center;
    background-color: transparent;
    height: 28px;
    margin-bottom: 4px;

    &--sticky {
      background-color: $light-grey-color-100;
      transition: background-color 200ms ease-in-out;
    }
  }

  @include mq('desktop-wide') {
    grid-template-columns: 15fr 70fr 15fr;
  }

  &--cover {
    height: $navheader-height;

    @include mq('desktop-s') {
      height: $product-detail-header-desktop-height;
    }
  }

  &--empty {
    grid-template-columns: 1fr;
  }

  .breadcrumbs {
    &__icon {
      width: auto;
      margin: 0 6px;
      color: $text-color-lighten;
      font-size: rem(14);
      line-height: rem(20);
    }

    &__label:last-child {
      color: $gray-color-700;
      pointer-events: none;
    }
  }

  .nav-header:nth-child(2) {
    animation: fade-in 350ms forwards;

    .nav-header__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__category {
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(18);
    color: $text-color;
  }

  &__grid {
    display: grid;
    grid-template-columns: 50px 1fr 152px;
    gap: 12px;
    align-items: center;
    transition: all 100ms 350ms ease-in-out;
    opacity: 0;
    transform: translateY(-10px);
    max-width: 1180px;
    width: 100%;
    margin: auto;
    visibility: hidden;

    &--visible {
      transition: all 350ms ease-in-out;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  &__image-container {
    padding: 2px;
    border-radius: 4px;
    width: 50px;
    height: 50px;
    background-color: white;
  }

  &__close-button {
    justify-self: end;
  }

  &__description {
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(20);
    color: $text-color-super-darken;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__details-grid {
    display: grid;
    gap: 12px;
    grid-template-columns: max-content auto;
  }

  .dish-price {
    margin: 0;
  }

  &__details {
    font-weight: 400;
    font-size: rem(12);
    line-height: rem(16);
    color: $text-color;
    margin: 0;

    @include truncate;
  }

  .product-detail-action .marmita-counter {
    border-color: $light-grey-color-300;
  }

  .report-button {
    color: $black-color;
    text-decoration: underline;
    font-size: rem(14);
    padding: 0;
  }
}
