@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-tooltip {
  &__container {
    display: inline-block;
    width: calc(100% - 40px);
    position: absolute;
    z-index: 100;
    background: $input-color;
    box-shadow: 0 3px 16px rgb(0 0 0 / 15%);
    border-radius: $border-radius-default;
    padding: $padding-default;
    bottom: 100%;

    @include mq('desktop-s') {
      max-width: 360px;
    }

    &::before {
      content: '';
      display: block;
      transform: rotate(45deg);
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: -5px;
      left: 20px;
      border-radius: 5px;
      background: $input-color;
    }
  }

  &__content {
    font-size: rem(14);
    color: #fff;
  }
}
