@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$max-container-width: 1180px;
$media-container-width: $max-container-width + 100px;

.product-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  &--page {
    background-color: $light-grey-color-100;
    max-width: none !important;
    width: 100%;
    margin-right: 20px;
  }

  @include mq('desktop-s') {
    width: 100%;
    max-width: $max-container-width;
    flex: none;
    display: grid;
    grid-template-columns: 1fr 1fr 66px;
    padding: 20px;
    column-gap: 30px;
    align-items: flex-start;
    margin: 0 auto;
  }

  @include mq('desktop-ultrawide') {
    padding: 40px;
  }

  &__image-container {
    height: 290px;
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-self: center;

    @include mq('desktop-s') {
      align-items: flex-start;
    }

    @include mq('desktop') {
      height: 320px;
    }

    @include mq('desktop-ultrawide') {
      height: 466px;
    }
  }

  &__image {
    width: 180px;
    height: 180px;
    margin: auto;

    &--overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgb(0 0 0 / 5%);
    }

    @include mq('desktop-s') {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin: 0;
    }
  }

  &__info {
    background-color: white;
    border-radius: 10px 10px 0 0;
    padding: 28px 16px;
    flex: 1;

    @include mq('tablet') {
      padding-left: 22px;
      padding-right: 22px;
    }

    @include mq('desktop-s') {
      flex: none;
      border-radius: 12px;
      min-height: 320px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    @include mq('desktop-wide') {
      max-width: 516px;
      justify-self: end;
    }
  }

  &__description {
    font-size: rem(20);
    font-weight: 400;
    line-height: rem(26);
    color: #3e3e3e;
    margin-bottom: 12px;
  }

  &__price {
    flex: 1;
  }

  .product-card__price {
    font-weight: 500;
    font-size: rem(20);
    line-height: rem(22);
    margin-bottom: 16px;

    &--discount-percentage {
      font-size: rem(12);
      line-height: rem(16);
      padding: 2px 6px;
      border-radius: 6px;
    }

    &--info-wrapper {
      margin-top: 8px;
    }

    &--original {
      font-size: rem(14);
      line-height: rem(18);
    }
  }

  &__weighable {
    font-weight: 300;
    font-size: rem(12);
    line-height: rem(20);
    color: $text-color;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 2px;

    &--value {
      justify-self: end;
    }
  }

  &__divider {
    background: $light-grey-color-100;
    border-radius: 4px;
    height: 1px;
    margin: 20px 0 12px;
  }

  &__warning {
    font-weight: 300;
    font-size: rem(12);
    line-height: rem(16);
    color: $text-color;
    display: grid;
    grid-template-columns: 26px auto;
    column-gap: 8px;
    margin-bottom: 12px;
    align-items: center;
  }

  &__button {
    margin-top: 12px;
  }

  &__floating-actions {
    display: none;

    @include mq('desktop-s') {
      display: block;
    }
  }

  &__button-share {
    width: 60px;
    height: 60px;
  }

  &__tooltip {
    cursor: default;

    &__container {
      min-width: auto;
      max-width: none;
      width: 166px;
      padding: 12px;
      text-align: center;
      font-size: rem(16);
      line-height: rem(20);
      border-radius: 0 0 6px 6px;
      box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
      margin-bottom: -12px;
      transition: 200ms ease-in-out;
      transform: translate(-50px, 6px);

      &.product-detail__tooltip--success {
        background-color: $green-color;
        color: #fff;

        &::after {
          border-bottom-color: $green-color;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: calc(100% - 1px);
        left: 50%;
        transform: translateX(-50%);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid #fff;
        transition: border 200ms ease-in-out;
      }
    }

    &__content {
      padding: 0;
    }
  }

  .product-detail-action {
    margin-top: 12px;
  }

  .take-X-pay-Y-tag {
    margin: 0 0 12px;
  }
}
