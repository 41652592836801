@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-observation-form {
  display: block;
  padding: 0 $padding-default;

  @include mq('desktop-s') {
    padding: 0 40px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0 10px;
  }

  &__label {
    margin: 0;
    font-weight: 500;
    font-size: rem(16);
    color: $text-color;
  }

  &__counter {
    color: #a6a6a6;
    font-weight: 100;
    font-size: rem(14);
  }

  &__textarea {
    border-radius: $border-radius-default;
    border: 1px solid $light-grey-color-300;
    background-color: #fff;
    margin-bottom: 10px;
    font-size: rem(16);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    color: $text-color;
    font-weight: 400;
    resize: none;
    height: calc(3.2em + 20px);
    min-height: calc(3.2em + 20px);
    padding: 10px;

    @include mq('desktop-s') {
      height: calc(4em + 40px);
      min-height: calc(4em + 40px);
      padding: $padding-default;
    }

    &::placeholder {
      color: #a6a6a6;
      opacity: 1;
      font-weight: 100;
    }
  }
}
