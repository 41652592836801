@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-carousel {
  margin: auto;

  .marmita-scrollbox {
    height: 246px;

    &__item {
      display: flex;
    }

    &__container {
      grid-auto-flow: column;
      display: grid;
      grid-gap: 12px;
      margin: 0;
      width: fit-content;
      padding: 0 16px;

      @include mq('tablet') {
        padding: 0;
      }

      & > * {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
