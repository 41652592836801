@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-card-scale-price {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: $text-color-super-darken;

  &__price-unit {
    margin-left: 2px;
    color: $text-color;
    font-size: rem(14);

    @include mq('tablet') {
      margin-left: 4px;
    }
  }

  &__scale-price {
    color: $green-color;
  }
}
