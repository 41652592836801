@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.search-placeholder {
  &__tab {
    height: 50px;
  }

  &__restaurants {
    display: block;

    @include mq('tablet') {
      display: grid;
      grid-template: 1fr / 1fr 1fr 1fr;
      grid-gap: 20px;
    }
  }
}
