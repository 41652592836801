@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-price {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: rem(14);
  color: $input-color;

  &__min {
    margin-bottom: 10px;
    color: $input-color;
    font-size: rem(16);
  }

  &__discount {
    color: $green-color;
    margin-bottom: 10px;
  }

  &__original {
    position: relative;
    font-size: rem(12);
    color: $medium-grey-color-lighten;
    text-decoration: line-through;
    margin-left: 6px;
  }
}
