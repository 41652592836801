@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-search-input {
  position: relative;
  width: 100%;

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 1;

    &::after {
      content: '';
      width: calc(100% + 40px);
      height: 100px;
      top: -22px;
      left: -40px;
      background-color: white;
      position: absolute;
      z-index: -1;

      @include mq('tablet') {
        left: -20px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -22px;
      width: calc(100% + 40px);
      height: 22px;
      left: -40px;
      box-shadow: 0 5px 7px 0 rgb(255 255 255 / 90%);
      z-index: -1;

      @include mq('tablet') {
        left: -20px;
      }
    }
  }

  &__icon {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;
    margin: 0;

    @include colorize-icon($primary-color);

    @include mq('tablet') {
      left: 25px;
    }
  }

  &__field {
    display: flex;
    height: 54px;
    width: 100%;
    padding: 5px 35px 5px 50px;
    border: 1px solid $light-grey-color-100;
    font-size: rem(16);
    line-height: em(20);
    font-weight: 300;
    background: $light-grey-color-200;
    border-radius: $border-radius-default;

    &::placeholder {
      color: $search-placeholder-home;
      font-size: rem(16);
      font-weight: 300;
    }

    @include mq('tablet') {
      padding: 5px 35px 5px 70px;
    }
  }

  &__erase-button {
    background: transparent;
    border: none;
    margin: 0;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__button {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: text;
  }

  &__powered-by {
    position: absolute;
    bottom: -25px;
    right: 0;

    svg {
      height: 14px;
      width: 116px;
    }
  }
}
