@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-search-step {
  position: relative;
  display: grid;
  min-height: 100%;
  width: 100%;
  background-color: white;
  grid-row-gap: 20px;
  grid-auto-rows: min-content;

  &__title {
    color: $text-color-super-darken;
    font-size: rem(18);
    line-height: 22px;
    text-align: center;
    display: none;

    @include mq('tablet') {
      display: block;
    }
  }

  &__results {
    padding-top: 5px;
  }

  .btn-address {
    border-bottom: 1px solid $light-grey-color-200;
  }

  .no-results {
    display: grid;
    margin-bottom: auto;
    grid-row-gap: 5px;
    text-align: center;

    @include mq('tablet') {
      padding-top: 40px;
      margin: auto;
      width: 40%;
    }

    &__description {
      color: $medium-grey-color;
      font-size: rem(14);
      line-height: 20px;
      font-weight: 300;
    }

    .btn {
      margin: 0;
    }
  }

  .marmita-error-message {
    justify-content: center;
  }
}
