@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
body.test-user {
  $test-green-color: #50a773 !important;

  .checkout-payment__submit,
  .payment-action .btn,
  .restaurant-cart-footer__button {
    background-color: $test-green-color;
  }

  .desktop-checkout__header {
    background-color: $test-green-color;

    .btn-icon:hover {
      background-color: $test-green-color;
    }

    .icon-marmita {
      @include colorize-icon(#fff);

      svg > path {
        fill: white !important;
      }
    }
  }
}
