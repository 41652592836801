@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-delivery-bar {
  max-width: 100%;
  padding: 0 $padding-default;
  text-align: left;
  height: 65px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__title {
    color: $tertiary-color;
    font-size: rem(15);
    margin: 0 0 1px;
  }

  &__address {
    color: $almost-white-color;
    font-size: rem(18);
    margin: 0;
  }
}
