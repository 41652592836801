@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-product-list-content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__title {
    color: $text-color-super-darken;
    font-size: rem(14);
    line-height: rem(18);
    margin: 0;
  }
}
