@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.ph-cuisine-item {
  display: inline-block;

  &__figure {
    margin: 0;
    border-radius: 50%;
    width: 80px;
    height: 80px;

    @include mq('desktop-s') {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      margin: 0 auto;
    }

    &.ph-loading {
      border-radius: 50%;
    }
  }

  &__title {
    margin-top: 20px;
    height: 16px;
  }
}
