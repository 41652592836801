@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-card__price {
  font-size: rem(14);
  line-height: rem(18);
  font-weight: 500;
  color: $text-color-super-darken;

  @include mq('tablet') {
    font-size: rem(16);
  }

  &--discount {
    color: $green-color;
  }

  &--info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $space-thin-yellow;
  }

  &--discount-percentage {
    background-color: $green-color;
    padding: $space-thin-yellow;
    border-radius: $border-radius-default;
    color: white;
    font-size: rem(10);
    line-height: rem(13);

    @include mq('desktop') {
      font-size: rem(14);
    }
  }

  &--original {
    position: relative;
    font-size: rem(10);
    line-height: rem(16);
    font-weight: 400;
    color: $text-color-lighten;
    text-decoration: line-through;
    margin-left: $space-thin-yellow;

    @include mq('desktop') {
      font-size: rem(14);
    }
  }

  .product-card__details {
    margin-left: $space-thin-pink;
  }
}
