@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-additional {
  &__container {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  &__side-space {
    padding: 10px $padding-default;

    @include mq('desktop-s') {
      padding: 10px 40px;
    }
  }
}
