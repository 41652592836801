@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.context-card__minimized {
  transform: translateY(0);
  transition: all 200ms;
}

.groceries-item-modal-detail-is-open {
  .context-card {
    z-index: 10001;

    &__minimized {
      transform: translateY(50px);
    }
  }
}

.product-detail-modal {
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  background-color: $light-grey-color-100;
  width: 100%;

  .marmita-modal__inner-content-scroll {
    display: flex;
    min-height: 100%;
    flex-direction: column;
  }

  &--after-open {
    margin-top: calc(40px - var(--topOffset));
    height: calc(100% - 40px + var(--topOffset));

    @include mq('desktop-s') {
      top: calc(50% - 50px);
    }
  }

  @include mq('desktop-s') {
    border-radius: 10px 10px 0 0;
  }

  &__overlay {
    background-color: rgba(#000, 0.8);
    z-index: $product-detail-modal-z-index;
  }

  .error-state {
    margin: auto;

    button {
      display: none;
    }
  }

  &__cross-sell {
    flex: 1;
    background-color: #fff;

    @include mq('desktop-s') {
      border-radius: 10px 10px 0 0;
    }
  }

  &__cross-sell-mobile {
    padding-bottom: 84px;
  }
}
