@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-step-shell {
  height: 100%;
  padding: $padding-default 0;
  overflow-y: scroll;
  scrollbar-color: $light-grey-color-300 transparent;
  background-color: white;

  &::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: $light-grey-color-300;
  }

  @include mq('desktop') {
    padding: $padding-default-desktop 0;
  }

  > div {
    padding: 0 $padding-default;

    @include mq('desktop') {
      padding: 0 $padding-default-desktop;
    }
  }
}
