@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchandise-tags-info-content-tag {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  column-gap: 12px;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;

  &__icon {
    display: flex;
    align-items: center;
    height: 24px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__text {
    font-size: rem(14);
    line-height: rem(20);
    color: $gray-color-700;

    @include mq('desktop-s') {
      font-size: rem(12);
      line-height: rem(16);
    }
  }

  &__description {
    font-size: rem(12);
    line-height: rem(16);
    color: #717171;
    margin: 2px 0 0;

    @include mq('desktop-s') {
      font-size: rem(11);
      line-height: rem(13);
    }
  }
}
