@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-list {
  display: grid;
  grid-auto-rows: auto;
  gap: 10px;

  @include mq('tablet') {
    width: 100%;
  }

  &__search-button {
    @include mq('tablet') {
      display: none;
    }
  }
}
