@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-maps-message {
  opacity: 0;
  visibility: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 172px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 80px)) scale(0.7);
  background-color: white;
  border-radius: $border-radius-default;
  padding: 12px 16px;
  text-align: center;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  transition: all ease-in 50ms;
  pointer-events: none;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -9px;
    position: absolute;
  }

  &--active {
    opacity: 1;
    visibility: 1;
    transform: translate(-50%, calc(-50% - 80px)) scale(1);
  }

  &__title {
    color: $gray-color;
    font-size: rem(14);
    line-height: rem(18);
  }

  &__subtitle {
    color: $text-color;
    font-size: rem(12);
    line-height: rem(16);
    font-weight: lighter;
  }
}
