@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-finder {
  &__no-api {
    height: 100%;
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  &__powered-by {
    display: none;
  }

  &__complete-form {
    transform: translateY(100%);
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    background: linear-gradient(0deg, rgb(255 255 255 / 100%) 94%, rgb(255 255 255 / 20%) 100%);
    justify-content: center;
    align-items: flex-end;
    transition: transform 300ms $ease-out-quad, opacity 300ms $ease-out-quad;
    z-index: 1;
    padding-top: 40px;
    height: calc(100% - 90px);

    &--active {
      transform: translateY(0);
      opacity: 1;

      & ~ .address-maps__map {
        height: 150px;

        .address-maps-header {
          background: transparent;
        }
      }

      .address-map-step__powered-by {
        display: block;
        position: absolute;
        top: -20px;
        right: 20px;

        svg {
          height: 14px;
          width: 116px;
        }
      }
    }
  }
}
