@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-info--loading {
  border-bottom: 10px solid #e6e6e6;
  border-radius: $border-radius-default $border-radius-default 0 0;
  color: $masala-color;
  padding: 40px $padding-default 0 $padding-default;
  margin-top: 70px;
  position: relative;
  top: -5px;
  min-height: 165px;
  display: flex;
  flex-direction: column;

  @include mq('desktop-s') {
    border-bottom: 0;
    border-radius: 0;
    padding: 0;
    margin: 30px 0 0;
    top: 0;
  }

  @include mq('desktop') {
    max-width: 1366px;
    margin: auto;
    margin-top: 30px;
  }

  &-wrapper {
    @include mq('desktop-s') {
      padding: 0 $padding-default-desktop;
    }

    @include mq('desktop') {
      padding-right: 0;
    }
  }

  .ph-loading {
    margin: 0 0 10px;
  }

  .restaurant-info__title {
    width: 50%;
  }

  .restaurant-info__logo {
    border-radius: 100%;
  }

  .restaurant-info__link-more {
    margin: 30px 0 0;
    width: 50px;
  }
}

.restaurant-info__subtitle--loading {
  width: 60%;
  height: 25px;
}
