@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-info-serves {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 4px;

  &__title {
    font-size: rem(14);
    line-height: rem(16);
    color: $text-color-super-darken;
    font-weight: 500;
  }
}
