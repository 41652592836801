@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.product-detail-header-price {
  &__scale {
    font-size: rem(14);
    color: $green-color;

    &--original {
      font-size: rem(12);
      color: $medium-grey-color-lighten;
      text-decoration: line-through;
      margin-left: 6px;
    }
  }
}
