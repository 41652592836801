@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.address-list-step {
  position: relative;
  display: grid;
  min-height: 100%;
  width: 100%;
  background-color: white;
  grid-row-gap: $padding-default;
  grid-auto-rows: min-content auto;

  &--with-return-button {
    @include mq('mobile-l', max) {
      grid-auto-rows: 40px min-content auto;
    }
  }

  &--centralize-content {
    grid-auto-rows: min-content;
  }

  &__container {
    display: grid;
    grid-row-gap: $padding-default;
    grid-auto-rows: 40px 54px min-content auto;
    opacity: 0;
    height: 0;
    overflow: hidden;

    &--authenticated {
      grid-auto-rows: min-content;
    }

    &--visible {
      height: auto;
      opacity: 1;
      overflow: visible;
    }
  }

  &__title {
    color: $text-color-super-darken;
    font-size: rem(18);
    line-height: 22px;
    text-align: center;
  }

  &__back-btn {
    margin-top: 10px;

    @include mq('tablet') {
      display: none;
    }
  }
}
